<template>
    <section class="main-content-section">
        <div class="grid">
            <div class="col-1-1 center">
                <h2 class="page-title">{{currentStatus}}</h2>
            </div>
            <LoadingSpinner v-if="isLoading" />
            <div v-else class="col-8-12 push-2-12 sm-col-1-1 sm-push-0">
                <div class="textpage">
                    <ul v-if="statusData" class="order-costs">
                        <li><div class="col-8-12 xs-col-6-12"></div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{total}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{today}}</div>
                        </li>

                        <li>
                            <div class="col-8-12 xs-col-6-12">{{totalRewardsReservedLabel}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{totalRewardsReserved}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{totalRewardsReservedToday}}</div>
                        </li>

                        <li class="divider"></li>

                        <li v-for="(product, i) in statusData.products" :key="`product-${i}`">
                            <div class="col-8-12 xs-col-6-12">{{product.name}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{product.quantity}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{product.quantityToday}}</div>
                        </li>

                        <li class="divider"></li>

                        <li>
                            <div class="col-8-12 xs-col-6-12">{{placedOrders}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{statusData.confirmedOrderCount}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{statusData.confirmedOrderCountToday}}</div>
                        </li>
                        
                        <li>
                            <div class="col-8-12 xs-col-6-12">{{canceledOrders}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{statusData.canceledOrderCount}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{statusData.canceledOrderCountToday}}</div>
                        </li>
                        
                        <li>
                            <div class="col-8-12 xs-col-6-12">{{totalUsers}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{statusData.userCount}}</div>
                            <div class="col-2-12 xs-col-3-12 number flex justify-end">{{statusData.userCountToday}}</div>
                        </li>
                    </ul>

                    <div v-else class="flex justify-center align-center">
                        <h3>{{noResultsFound}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import agent from '../../api/agent';
import {mapGetters} from 'vuex';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

export default {
    name: 'Status',
    components: {LoadingSpinner},
    data() {
        return {
            statusData: null,
            isLoading: false,
            totalRewardsReserved: 0,
            totalRewardsReservedToday: 0
        }
    },
    created() {
        this.fetchStatusData();    
        this.setDocumentTitle();
    },
    computed: {
        ...mapGetters([
            // Texts
            'currentStatus',
            'total',
            'today',
            'placedOrders',
            'canceledOrders',
            'totalUsers',
            'metaTitle',
            'noResultsFound'
        ]),
        ...mapGetters({
            totalRewardsReservedLabel: 'totalRewardsReserved'
        })
    },
    methods: {
        setDocumentTitle() {
            document.title = this.currentStatus + this.metaTitle;
        },
        async fetchStatusData() {
            try {
                this.isLoading = true;
                const result = await agent.report.status();
                this.statusData = result;
                this.totalRewardsReserved = result.products.map(product => product.quantity).reduce((a, b) => a + b);
                this.totalRewardsReservedToday = result.products.map(product => product.quantityToday).reduce((a, b) => a + b);
                this.isLoading = false;
            } catch (error) {
                console.log(error.response);
                this.isLoading = false;
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/global-styles/variables';
.order-costs {
    list-style: none;

    li {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }
}

.number {
    font-weight: 700;
}

.divider {
    border-bottom: 2px solid $black;
}
</style>